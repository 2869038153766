import React from "react";
import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react";
import baseApi from "../Components/api";
import Config from "./config";

const CKTextEditor = ({ value, onChange }) => {
  const cloud = useCKEditorCloud({
    version: "44.3.0",
    premium: true,
  });

  if (cloud.status === "error") {
    return <div>Error!</div>;
  }

  if (cloud.status === "loading") {
    return <div>Loading...</div>;
  }

  const {
    ClassicEditor,
    Essentials,
    Paragraph,
    Bold,
    Italic,
    Font,
    List,
    Table,
    TableToolbar,
    Heading,
    Link,
    BlockQuote,
    Underline,
    Code,
    Alignment,
    SourceEditing,
    Image,
    ImageUpload,
    MediaEmbed,
    SimpleUploadAdapter,
    PasteFromOffice,
  } = cloud.CKEditor;

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
      config={{
        licenseKey: Config.auth.CKEditor_Token,
        plugins: [
          Essentials,
          Paragraph,
          Bold,
          Italic,
          Font,
          List,
          Table,
          TableToolbar,
          Heading,
          Link,
          BlockQuote,
          Underline,
          Code,
          Alignment,
          SourceEditing,
          Image,
          ImageUpload,
          MediaEmbed,
          SimpleUploadAdapter,
          PasteFromOffice,
        ],
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontBackgroundColor",
          "fontColor",
          "alignment",
          "|",
          "bold",
          "italic",
          "underline",
          "code",
          "|",
          "ImageUpload",
          "mediaEmbed",
          "|",
          "link",
          "insertTable",
          "blockQuote",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "sourceEditing",
        ],
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        simpleUpload: {
          uploadUrl: baseApi + "text-editor/image-upload",
          withCredentials: false,
        },
      }}
    />
  );
};

export default CKTextEditor;
