class Config {
  constructor() {
    this.auth = {
      baseurl: "https://www.deaninfotech.com/deaninfotechapi/public/api/",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRlOWIzZTIwZGZhOTY5MGQwNzkzMDYyZmM5YWNiODQ1NzNhODdkZDJiZWRmNzkzZmFhY2Y5ZDAzMTZkMWMyMjU1MmVjY2VmYjBmY2M2ZGU0In0.eyJhdWQiOiIxIiwianRpIjoiZGU5YjNlMjBkZmE5NjkwZDA3OTMwNjJmYzlhY2I4NDU3M2E4N2RkMmJlZGY3OTNmYWFjZjlkMDMxNmQxYzIyNTUyZWNjZWZiMGZjYzZkZTQiLCJpYXQiOjE2MjI3OTI0MDIsIm5iZiI6MTYyMjc5MjQwMiwiZXhwIjoxNjU0MzI4NDAyLCJzdWIiOiIxMiIsInNjb3BlcyI6W119.r5xLjo_7oOcq66sOfWeFOoTIwZQK9GZ3BzCEsqsSi9JJw2bV-Uvr8OZWbp2M9K0x1mz_z8z2aaHKfhrccF4ZzfPgS-ePKH_Kre4bmZVPXP3-mcE2S1JB31F03Q8RcUk_MCXYvUUN9TRiqnJPKVRQ41nYGHL7fQRbk3MRSY6FRXYOay6CyHORG-OWUuDuNAdFmpYTV7c0OKwdNcDuzow8aRGQRT4lJO_m8VAh9tzeDv5bZ1tUZiR_SgevvYhGpC-8rbdieer9r8SOoFcc7ZCyZnbqSwWDI9rYtFlP3QO1aNOoVvqW8-pOEFkVHZW01HLtju2X2E2hyeb1YwZoxXjgLyHNWMCCX1o1r53i3grcpDfKKQ3a7kBX-xIALWa10M4e-Dhj8qbCrDuc4hjkKBtAhW5AhoVtMAtNwqzgh2lhaAf9EJ9v7hWWimgBgc4pqI-KFkmcNbt-bETTZovv8JbhGeW6_xCQujSIHihXPNamqgDyyYLOhbdPSSIeK9jzD9wnuz1RcTRndHJ1B74CRo8lKarN2dxu9vbe6kO1fUBXFYOpgqizMjBhGmcZbZyLzDJsqumIKhU6MYEHr-oiUn_dpN38Lv0d7j6j-mR2PgYegQ60QtYrF6eQgsS_JZz6gIH63Z0WgbljpnpHBIcumkP2bpdbj5ynqe5S6CdV19Fg_8E",
      CKEditor_Token:
        "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NzUxNzQzOTksImp0aSI6ImZhOTA3YjZlLTNkMzktNDU0OS05YzEwLTUyZTY0NGI1NDZiNiIsImxpY2Vuc2VkSG9zdHMiOlsiYWRtaW4uZGVhbmluZm90ZWNoLmNvbSJdLCJ1c2FnZUVuZHBvaW50IjoiaHR0cHM6Ly9wcm94eS1ldmVudC5ja2VkaXRvci5jb20iLCJkaXN0cmlidXRpb25DaGFubmVsIjpbImNsb3VkIiwiZHJ1cGFsIl0sImZlYXR1cmVzIjpbIkRSVVAiXSwidmMiOiIxMjJlZjI0YSJ9.eleVtD1tWNe9Tpc1uxZo41Bft85_d0wsIQJmHfKWe_lz0snCVcXUYp8eesc2GTgT2qQ96t__s_3H4woOp4ARww", // Production
      // "eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NzUwODc5OTksImp0aSI6IjY2YmI1ZTc1LTM5NDctNDhiNy1iYzRjLWMwNGU1Mjg1OTNiNyIsImxpY2Vuc2VkSG9zdHMiOlsiMTI3LjAuMC4xIiwibG9jYWxob3N0IiwiMTkyLjE2OC4qLioiLCIxMC4qLiouKiIsIjE3Mi4qLiouKiIsIioudGVzdCIsIioubG9jYWxob3N0IiwiKi5sb2NhbCJdLCJ1c2FnZUVuZHBvaW50IjoiaHR0cHM6Ly9wcm94eS1ldmVudC5ja2VkaXRvci5jb20iLCJkaXN0cmlidXRpb25DaGFubmVsIjpbImNsb3VkIiwiZHJ1cGFsIl0sImxpY2Vuc2VUeXBlIjoiZGV2ZWxvcG1lbnQiLCJmZWF0dXJlcyI6WyJEUlVQIl0sInZjIjoiMTczYzNlODcifQ.hNIFvcWxYS0TIm_6l1JGx1FcYgF5KcUvd0_XMtPfPRRKIfo1WTwLjgWbWmEl4lcEbkkjRVPNNNDxftsf4YqRDA", // Development
    };
  }
}

export default new Config();
