import React, { Component, Fragment } from "react"

// import Header from "../Header/Header"
// import Footer from "../Footer/Footer"
import BlogDiv from "./Components/BlogDiv"
// import ImageWebp from "../../ImageWebp"
// import { Helmet } from "react-helmet"

// const banner = require("../sitemap/images/aboutus-banner.jpg")

export default class Blog extends Component {
  render() {
    // console.log("Akshay")
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <section className="internal-banner sitemap_banner blog_banner"> */}
        {/* <ImageWebp srcWebp={banner} src={banner} className="img-responsive" /> */}
        {/* <img src={banner} alt="" className="img-responsive" /> */}
        {/* </section> */}
        <BlogDiv />
        {/* <Footer /> */}
      </Fragment>
    )
  }
}
